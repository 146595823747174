.gabz{
    border:1px solid red;
}
.custom-header {
  height: 734px; /* Adjust this value to your desired height */
}


@keyframes flame-flicker {
  0% {
    transform: scale(1) translateY(0);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.2) translateY(0px);
    opacity: 1;
  }
}

