.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 100%;
  background-color: #1572B6; /* Change this color to match your design */
  transition: width 0.1s linear;
}
.slick-arrow{
display:none !important; /* force slick arrows to hide */
}