.fade-out {
  opacity: 0;
  background:red;
  transition: opacity 1s;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s;
}

/* MainLayout.css */

/* Add this CSS for the flame effect */
.flames-container {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 14px;
  margin-left: 4px;
}

.flames {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 10px;
  height: 18px;
  background: radial-gradient(circle at bottom, rgba(255,154,0,1) 0%, rgba(255,97,0,1) 60%, rgba(255,255,255,0) 80%);
  border-radius: 50% 50% 40% 40%;
  transform: translateX(-50%);
  animation: flicker 0.8s infinite alternate;
}

.flames::before {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 10%;
  width: 8px;
  height: 17px;
  background: radial-gradient(circle at bottom, rgba(255,218,0,1) 0%, rgba(255,106,0,1) 60%, rgba(255,255,255,0) 80%);
  border-radius: 50% 50% 40% 40%;
  transform: translateX(-50%);
  animation: flicker 0.8s infinite alternate;
}

.flames::after {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 20%;
  width: 5px;
  height: 8px;
  background: radial-gradient(circle at bottom, rgba(255,255,0,1) 0%, rgba(255,165,0,1) 60%, rgba(255,255,255,0) 80%);
  border-radius: 50% 50% 40% 40%;
  transform: translateX(-50%);
  animation: flicker 0.8s infinite alternate;
}

@keyframes flicker {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-2px) scale(1.1);
    opacity: 0.8;
  }
}


/* Add these styles to MainLayout.css */

.circle {
  position: relative;
  top: 0;
  left: 50px;
  width: 0;
  height: 0;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  transition: width 0.5s, height 0.5s, transform 0.5s;
  z-index: 100;
}

.circle-hovered {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  transform: scale(3); /* Adjust scale as needed */
}
